<template>
    <div class="touch-section">
        <div class="touch-section__inner" :class="{ 'with-requisites': $slots['requisites'], 'touch-section__inner--full' : full }">
            <div class="fields-section">
                <h3>{{ computedTitle }}</h3>
                <CRInput
                    class="input"
                    v-model="form.name"
                    :label="businessForm ? $t('common.business_name') : $t('common.name')"
                    :placeholder="businessForm ? $t('placeholder.enterBusinessName') : $t('placeholder.enterYourName')"
                    required
                    :errors="errors?.name"
                    @onChange="$filters.clearError(errors, 'name')"
                />
                <CRInput
                    v-if="businessForm"
                    class="input"
                    v-model="form.business_code"
                    :label="$t('common.business_code')"
                    :placeholder="$t('placeholder.enterBusinessCode')"
                    required
                    :errors="errors?.business_code"
                    @onChange="$filters.clearError(errors, 'business_code')"
                />
                <CRInput
                    v-if="businessForm"
                    class="input"
                    v-model="form.business_workers_count"
                    :label="$t('common.business_workers_count')"
                    :placeholder="$t('placeholder.enterBusinessWorkersCount')"
                    required
                    :errors="errors?.business_workers_count"
                    @onChange="$filters.clearError(errors, 'business_workers_count')"
                />
                <CRInput
                    class="input"
                    v-model="form.email"
                    :label="$t('common.email')"
                    :placeholder="$t('placeholder.enterEmail')"
                    required
                    :errors="errors?.email"
                    @onChange="$filters.clearError(errors, 'email')"
                />
                <CRInput
                    v-if="businessForm"
                    class="input"
                    v-model="form.phone_number"
                    :label="$t('common.phone_number')"
                    :placeholder="$t('placeholder.enterPhone')"
                    required
                    :errors="errors?.phone_number"
                    @onChange="$filters.clearError(errors, 'phone_number')"
                />
                <CRTextarea
                    class="input textarea"
                    v-model="form.message"
                    :label="$t('common.message')"
                    :placeholder="$t('placeholder.enterMessage')"
                    required
                    :errors="errors?.message"
                    @onChange="$filters.clearError(errors, 'message')"
                />
                <CRButton v-if="user" class="mt-10" @click="setAccountInformation" pattern="thin">
                    {{ $t('buttons.setAccountInformation') }}
                </CRButton>
                <RequiredText class="tooltip" />
                <CRButton :loading="isLoading" @click="onSendClick">{{ $t('buttons.send') }}</CRButton>
            </div>
            <div v-if="full" class="requisites">
                <slot name="requisites" />
                <div class="footer-info footer-info-requisites">
                    <div class="footer-item">
                        <h5>{{ $t('common.phone') }}:</h5>
                        <a @click="sendCallGoogleEvent" href="tel:+37064508975">+37064508975</a>
                    </div>
                    <div class="footer-item">
                        <h5>{{ $t('common.email') }}:</h5>
                        <a href="mailto:info@cityrush.lt">info@cityrush.lt</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="!$slots['requisites'] && withImage" class="touch-section__inner img__wrapper">
            <img src="@/assets/images/backgrounds/get-in-touch-bg.png" alt="get-in-touch-bg" />
        </div> -->
        <SuccessModal
            v-if="modalShown"
            @close="modalShown = false"
            :title="$t('main.getInTouch.modalTitle')"
            :description="$t('main.getInTouch.modalMessage')"
        />
    </div>
</template>

<script>
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
    import SuccessModal from '@/components/Modals/SuccessModal';
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import { FeedBackApi } from '@/api';
    import { mapGetters } from 'vuex';
    import { GoogleAnalyticsEnum, ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'GetInTouchSection',
        components: { CRInput, CRTextarea, RequiredText, CRButton, SuccessModal },
        props: {
            businessForm: {
                type: Boolean,
                default: false,
            },
            full: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            withImage: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                modalShown: false,
                form: {
                    name: '',
                    email: '',
                    message: '',
                    business_code: '',
                    phone_number: '',
                    business_workers_count: '',
                    business: this.businessForm,
                },
                errors: {},

                isLoading: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),
            computedTitle() {
                return this.title ? this.title : this.$t('main.getInTouch.title');
            },
        },
        methods: {
            async onSendClick() {
                this.isLoading = true;

                try {
                    await FeedBackApi.store(this.form);
                    fbq('track', ConversionEventNameEnum.CONTACT);
                    this.form = {
                        name: '',
                        email: '',
                        message: '',
                        phone_number: '',
                        business_workers_count: '',
                        business_code: '',
                        businessForm: false,
                    };
                    this.modalShown = true;
                } catch (error) {
                    this.errors = error.errors;
                } finally {
                    this.isLoading = false;
                }
            },

            setAccountInformation() {
                this.form.name = this.user.firstName;
                this.form.email = this.user.email;
            },

            sendCallGoogleEvent() {

                fbq('track', ConversionEventNameEnum.CONTACT);

                return this.$gtag.event(GoogleAnalyticsEnum.CALL, {
                    url: window.location.href,
                    customer_info: this.user ?? null,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .touch-section {
        width: 100%;

        h3 {
            margin-bottom: 46px;
        }

        .input {
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .textarea {
            :deep(.cr-textarea) {
                min-height: 115px;
            }
        }

        .footer-info {
            @include column-align-start;

            margin-top: 24px;

            .footer-item {
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    font-size: $font-30;
                    font-weight: 700;

                    color: $primary;
                    text-decoration: underline;
                }
            }
        }

        .requisites {
            display: flex;
            flex-direction: column-reverse;
        }

        img {
            display: none;
        }
    }

    @include media($lg) {
        .touch-section {
            @include row-align-start-justify-between;

            h3 {
                font-size: $font-24;

                margin-bottom: 38px;
            }

            .input {
                margin-bottom: 23px;
            }

            .textarea {
                :deep(.cr-textarea) {
                    min-height: 160px;
                }
            }

            .tooltip {
                margin-bottom: 30px;
            }

            .footer-info {
                @include row-align-center;

                margin-top: 45px;

                .footer-item {
                    margin-bottom: 0;
                    margin-right: 55px;
                }
            }

            img {
                display: block;
                border-radius: $br-10;
            }

            .touch-section__inner {
                width: 50%;

                &--full {
                    width: 100%;
                }
            }

            .with-requisites {
                @include row-align-start;

                width: 100%;

                .fields-section {
                    width: 40%;
                    margin-right: 100px;
                }

                .requisites {
                    flex-direction: column;
                    width: 40%;

                    .footer-info-requisites {
                        @include column-align-start;
                        flex-wrap: wrap;

                        .footer-item {
                            margin-bottom: 25px;
                        }
                    }
                }
            }

            .img__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                width: 44%;
            }
        }
    }
</style>
